import React, { Dispatch } from 'react';
import CustomTable from '../../../tables/CustomTable';
import GeneralCard from '../../../general/GeneralCard';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../styling/theme';
import SimpleLineChart from './AssetsCharts';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { set } from 'lodash';

type ColumnDefinition = {
  display_name: string;
  data_type: 'string' | 'percent_2f' | 'float_2f' | 'timeseries';
  column_name: string;
};

export interface MarketsAssetsProp {
  [key: string]: string | number;
}

export interface AssetDetails {
  column_name: string;
  data_type: string;
  display_name: string | null;
  value: string | number | TimeSeriesArray;
}

export interface TimeSeriesArray {
  value: Array<[string, string | number]>;
}

export interface MarketsAssetsPropArray {
  marketAssetData: MarketsAssetsProp[];
  columns: ColumnDefinition[];
  setGraph: (value: string) => void;
  setNews: Dispatch<React.SetStateAction<string>>;
}

function getVarExceedanceColor(varExceedance: number) {
  // Greens
  if (varExceedance < -1) return '#6AE84A';
  if (varExceedance < -0.7) return '#A2F08E';
  if (varExceedance < -0.4) return '#C7F6BB';
  if (varExceedance < -0.1) return '#ECFCE8';

  // Reds
  if (varExceedance >= 1) return '#ff0000';
  if (varExceedance >= 0.7) return '#ff6767';
  if (varExceedance >= 0.4) return '#ff9a9a';
  if (varExceedance >= 0.1) return '#FFDAD5';

  return '#f9f9f9';
}

const useStyles = makeStyles({
  container: {
    padding: '0.5em',
  },
  assetTable: {
    '& tbody': {
      '&::-webkit-scrollbar': {
        width: '0.5rem',
      },
      '&::-webkit-scrollbar-track': {
        border: '0.2rem solid white',
        backgroundColor: mainColors.lightGrey,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: mainColors.mainBlue,
        borderRadius: '1rem',
      },
    },
    overflow: 'hidden',
    padding: '0.5em 1em 2em',
    '& th, td': {
      padding: '0.2em',
      textAlign: 'center',
    },
  },
});

// to format value based on data_type and field name
function formatValue(
  data: any,
  data_type: ColumnDefinition['data_type'],
): string | ReactElement {
  if (data === null || data === undefined) {
    return '';
  }

  let value = data;

  if (data_type === 'timeseries') {
    const timeseriesValue = data.mini_timeseries;

    // For the small graphs, we are only using the last 30 days (or roughly a month) for the data
    const dataPoints = Array.isArray(timeseriesValue)
      ? (timeseriesValue as [string, number][]).map(([, value]) => value)
      : [];
    const return1MValue =
      typeof data.return_1m === 'number' ? data.return_1m : 0;

    return (
      <SimpleLineChart dataPoints={dataPoints} returnDay={return1MValue} />
    );
  }
  // Check if the value is a percentage based on data_type (e.g., "percent_3f")
  if (data_type.startsWith('percent_') && typeof value === 'number') {
    value = value * 100; // Convert to percentage
  }
  if (typeof value === 'number') {
    // Check what number it is
    const match = data_type.match(/_(\d+)f/);
    const decimalPlaces = match ? parseInt(match[1], 10) : 2; // Default to 2 decimal places
    let formattedValue = value.toFixed(decimalPlaces);
    // Append '%' if it's a percentage
    if (data_type.startsWith('percent_')) {
      formattedValue += '%';
    }
    return formattedValue;
  }
  // Return the value as a string if it's not a number
  return value as string;
}

function constructColumns(columns: ColumnDefinition[]): any[] {
  return columns.map((column) => {
    const matchingColumn = marketAssetColumnStyles.find(
      (col) => col.field === column.column_name,
    );

    if (matchingColumn) {
      return {
        title: column.display_name,
        field: column.column_name,
        cellStyle: matchingColumn.cellStyle,
        render: (rowData: any) => {
          if (column.data_type === 'timeseries') {
            return formatValue(rowData, column.data_type);
          } else {
            return formatValue(rowData[column.column_name], column.data_type);
          }
        },
      };
    } else {
      return {
        title: column.display_name,
        field: column.column_name,
        cellStyle: {},
        render: (rowData: any) => {
          return formatValue(rowData[column.column_name], column.data_type);
        },
      };
    }
  });
}

// All Column Styles
const marketAssetColumnStyles = [
  {
    field: 'asset_type',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    field: 'asset_name',
    cellStyle: {
      fontWeight: 'bold',
      width: '40em',
    },
  },
  {
    field: 'current_price',
    cellStyle: {},
  },
  {
    field: 'difference',
    cellStyle: { borderRight: '1px solid gray' },
  },
  {
    field: 'return_1d',
    cellStyle: {},
  },
  {
    field: 'var_1d',
    cellStyle: {},
  },
  {
    field: 'var_ratio_1d',
    cellStyle: (data: MarketsAssetsProp[], rowData: any) => {
      return {
        borderRight: '1px solid gray',
        backgroundColor: getVarExceedanceColor(rowData.var_ratio_1d),
      };
    },
  },
  {
    field: 'return_1w',
    cellStyle: {},
  },
  {
    field: 'var_1w',
    cellStyle: {},
  },
  {
    field: 'var_ratio_1w',
    cellStyle: (data: MarketsAssetsProp[], rowData: any) => {
      return {
        borderRight: '1px solid gray',
        backgroundColor: getVarExceedanceColor(rowData.var_ratio_1w),
      };
    },
  },
  {
    field: 'return_1m',
    cellStyle: {},
  },
  {
    field: 'var_1m',
    cellStyle: {},
  },
  {
    field: 'var_ratio_1m',
    cellStyle: (data: MarketsAssetsProp[], rowData: any) => {
      return {
        backgroundColor: getVarExceedanceColor(rowData.var_ratio_1m),
      };
    },
  },
  {
    field: 'mini_timeseries',
    cellStyle: {},
  },
];

const MarketAssets: React.FC<MarketsAssetsPropArray> = ({
  marketAssetData,
  columns,
  setGraph,
  setNews,
}) => {
  const classes = useStyles();

  const handleRowClick = (
    event?: React.MouseEvent<Element, MouseEvent>,
    rowData?: MarketsAssetsProp,
  ) => {
    const asset = rowData?.asset_name ?? 'MSCI World Index US CH0047784696';

    if (!event) return;
    const tableRow = (event.target as HTMLElement).closest('tr')!;
    const tableBody = (event.target as HTMLElement).closest('tbody')!;

    const indexOfClickedTableRow = tableRow.getAttribute('index');

    // Set the ref to the index of the clicked row

    // Loop through all rows in the table body and set background color
    tableBody.querySelectorAll('tr').forEach((row) => {
      const rowIndex = row.getAttribute('index');
      row.style.backgroundColor =
        rowIndex === indexOfClickedTableRow ? mainColors.lightGrey : 'inherit';
    });

    // Ensure that the asset is a string before calling setGraph
    if (typeof asset === 'string') {
      setGraph(asset);
      // Set the selected asset to highlight the row
    } else {
      console.error('Expected a string for asset_name.value, but got:', asset);
    }

    if (typeof asset === 'string') {
      const assetQueryForNews = asset.split(' ');

      const regex = /^[A-Z0-9]{12}$/; // This is removing the ISIN code from the asset name

      const lastItem = assetQueryForNews[assetQueryForNews.length - 1];
      if (regex.test(lastItem)) {
        assetQueryForNews.pop();
        setNews(assetQueryForNews.join(' '));
        return;
      }
      setNews(assetQueryForNews.join(' '));
      return;
    }
    setNews('all');
  };

  return (
    <GeneralCard className={classes.container}>
      <CustomTable
        title="markets_overview_table"
        showToolbar={false}
        columns={constructColumns(columns)}
        data={marketAssetData}
        tableRootStyles={classes.assetTable}
        options={{
          maxBodyHeight: '40em',
          draggable: false,
          paging: false,
        }}
        onRowClick={handleRowClick}
      />
    </GeneralCard>
  );
};

export default React.memo(MarketAssets);
