import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useSelector } from 'react-redux';
import JOST_REGULAR from '../../../../../fonts';
import RiskSystemLogo from '../../../../../images/rsMainLogo.png';
import {
  clientNameSelector,
  configNameSelector,
} from '../../../../../redux/auth/selectors';
import {
  activeDateSelector,
  activePageSelector,
  activeSectionSelector,
  createActiveFundSelectorBySection,
  createPageDetailsSelector,
} from '../../../../../redux/pages/selectors';
import { componentsForExportSelector } from '../../../../../redux/pdfExport/selectors';
import { mainColors } from '../../../../../styling/theme';
import {
  ComponentIndex,
  PdfComponentType,
} from '../../../../../types/redux/pdfExports/pdfExportsStore';
import { mapStatusToColor } from '../../../../../utilities/colorUtilities';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';
import { handleLogo } from '../../../sidebar/LogoRender/LogoRender.component';
import CustomPdfExportButton from '../customPdfExportButton/CustomPdfExportButton.component';
import { IconButton, Tooltip } from '@mui/material';
import { Archive } from '@mui/icons-material';
import { specifyStatus } from '../../../../../utilities/generalMappings';
import useTitleBarDatePicker from '../../../../../hooks/useTitleBarDatePicker';

interface PdfGeneratorProps {
  pageTitle: string;
  fundName: string;
  fundId: string;
  pageId: string;
  positionDate?: string;
  analysisDate: string;
  clientName: string;
  configName: string;
  idenftifier?: string;
  chartWidth?: number;
}

interface ImageProps {
  src: string;
  width: number;
  height: number;
  alignment: 'left' | 'right' | 'center';
  proportionOfWidthOfPage: number;
  proportionOfHeightOfPage?: number;
  specificYPosition?: number;
  specificXPosition?: number;
  addTitle?: string;
}
export class PdfGenerator {
  // eslint-disable-next-line
  static UNIT: 'mm' = 'mm';
  static WIDTH = 210;
  static HEIGHT = 297;

  doc: any;
  pdfId: string;
  clientName: string;
  configName: string;
  pageTitle: string;
  positionDate?: string;
  analysisDate: string;
  fundName: string;
  currentY: number;
  widthsPopulated: number;
  currentPage: number;
  tallestTableInRow: number | null;
  orientation: 'portrait' | 'landscape';
  chartWidth: number;

  constructor({
    fundId,
    pageTitle,
    fundName,
    pageId,
    clientName,
    configName,
    positionDate,
    analysisDate,
    ...props
  }: PdfGeneratorProps) {
    this.orientation = 'landscape';
    this.doc = new jsPDF({
      unit: PdfGenerator.UNIT,
      format: [PdfGenerator.WIDTH, PdfGenerator.HEIGHT],
      orientation: this.orientation,
    });
    // this.doc.addFileToVFS('Cabin-Regular.ttf', cabinRegular);
    // this.doc.addFont('Cabin-Regular.ttf', 'Cabin Regular', 'normal');
    // this.doc.setFont('Cabin Regular', 'normal');
    this.doc.addFileToVFS('Jost-Regular.ttf', JOST_REGULAR);
    this.doc.addFont('Jost-Regular.ttf', 'JostRegular', 'normal');
    this.doc.setFont('JostRegular', 'normal');
    this.doc.setTextColor(mainColors.mainBlue);
    this.fundName = fundName;
    this.positionDate = positionDate;
    this.analysisDate = analysisDate;
    this.clientName = clientName;
    this.configName = configName;
    this.chartWidth = props.chartWidth || 0.48;
    this.pageTitle = pageTitle;
    this.widthsPopulated = 0;
    this.pdfId = props.idenftifier
      ? `${props.idenftifier}.pdf`
      : `${pageId}_${fundId}.pdf`;
    this.currentY = 20;
    this.currentPage = 1;
    this.tallestTableInRow = null;
    this.orientation = 'landscape';
  }

  addClientLogo(
    isLandscape = false,
    additionalProperties?: {
      // use a different image src to the current client
      imageSrc?: string;
      // use an adjusted y position, instead of always being at the top
      adjustedYPosition?: number;
      adjustedHeightProportion?: number;
      adjustedAlignment?: 'left' | 'center' | 'right';
    },
  ) {
    const imageSrc =
      additionalProperties?.imageSrc ||
      handleLogo(this.clientName, this.configName);
    const { height, width } = this.doc.getImageProperties(imageSrc);

    this.addImage({
      src: imageSrc,
      width: width,
      height: height,
      proportionOfWidthOfPage: 0.14,
      proportionOfHeightOfPage:
        additionalProperties?.adjustedHeightProportion || 0.03,
      alignment: additionalProperties?.adjustedAlignment || 'right',
      specificYPosition: additionalProperties?.adjustedYPosition || 5,
    });
  }

  addPageTitle() {
    this.doc.setFontSize(14);
    this.doc.text(
      `${this.pageTitle}${this.fundName ? ` - ${this.fundName}` : ''}`,
      5,
      8,
    );
  }
  addCopyrightText() {
    this.doc.setFontSize(10);
    this.doc.setTextColor('#8e8e8e');
    // Get the current year
    const year = new Date().getFullYear();
    this.doc.text(`© ${year} Maraging Funds`, 130, 208);
  }
  addPositionDate() {
    this.doc.setFontSize(10);
    this.doc.setTextColor('#5b5b5b');
    this.doc.text(`Position Date: `, 5, 14);
    this.doc.setTextColor('#ED8B00');
    if (this.positionDate) {
      this.doc.text(`${this.positionDate}`, 27, 14);
    } else {
      this.doc.text(`${this.analysisDate}`, 27, 14);
    }
  }

  addImage(imageProps: ImageProps) {
    const actualHeightOfPage =
      this.orientation === 'landscape'
        ? PdfGenerator.WIDTH
        : PdfGenerator.HEIGHT;
    const actualWidthOfPage =
      this.orientation === 'landscape'
        ? PdfGenerator.HEIGHT
        : PdfGenerator.WIDTH;

    let adjustedHeight: number;
    let adjustedWidth: number;
    if (imageProps.proportionOfHeightOfPage) {
      adjustedHeight = actualHeightOfPage * imageProps.proportionOfHeightOfPage;
      adjustedWidth = (adjustedHeight * imageProps.width) / imageProps.height;
    } else {
      adjustedWidth = actualWidthOfPage * imageProps.proportionOfWidthOfPage;
      adjustedHeight = (adjustedWidth * imageProps.height) / imageProps.width;
    }
    let x = 0;
    let y = imageProps.specificYPosition
      ? imageProps.specificYPosition
      : this.currentY;
    // handle alignment
    if (imageProps.specificXPosition) {
      x = imageProps.specificXPosition;
    } else if (imageProps.alignment === 'left') {
      x = 5;
    } else if (imageProps.alignment === 'center') {
      x = actualWidthOfPage / 2 - adjustedWidth / 2;
    } else {
      x = actualWidthOfPage - adjustedWidth - 5;
    }

    const getTitleLocation = (alignment: 'center' | 'left' | 'right') => {
      if (alignment === 'left') {
        return actualWidthOfPage / 4;
      } else if (alignment === 'right') {
        return (actualWidthOfPage / 4) * 3;
      } else {
        return actualWidthOfPage / 2;
      }
    };

    if (imageProps.addTitle) {
      this.doc.text(
        imageProps.addTitle,
        imageProps.specificXPosition
          ? imageProps.specificXPosition + adjustedWidth / 2
          : getTitleLocation(imageProps.alignment),
        y,
        {
          align: 'center',
        },
      );
      y += 5;
    }

    this.doc.addImage(
      imageProps.src,
      'PNG',
      x,
      y,
      adjustedWidth,
      adjustedHeight,
    );
  }

  async addChart(chartComponent: ComponentIndex) {
    const imageData = await chartComponent.handler();
    if (!chartComponent.dontMoveToNewPage) {
      this.doc.addPage('a4', 'landscape');
      this.currentY = 20;
    } else {
      this.currentY += 5;
    }
    this.addImage({
      src: imageData.data,
      width: imageData.width,
      height: imageData.height,
      proportionOfWidthOfPage: this.chartWidth,
      alignment: chartComponent.alignment ? chartComponent.alignment : 'center',
      addTitle: chartComponent.title,
      specificYPosition: chartComponent.specificYPosition
        ? chartComponent.specificYPosition
        : undefined,
      specificXPosition: chartComponent.specificXPosition
        ? chartComponent.specificXPosition
        : undefined,
    });
    // Adjust the y position after adding the chart.
    // For now don't do anything if specific position was indicets.
    if (
      !chartComponent.specificXPosition &&
      !chartComponent.specificYPosition &&
      !chartComponent.dontIncrementYPosition
    ) {
      this.currentY += imageData.height + 5;
      // this.doc.addPage('a4', 'landscape');
      // this.currentY = 20;
    } else {
      // if dont increment y is chosen, walk back y
      this.currentY -= 5;
    }
  }

  async addGeneralComponentImage(component: ComponentIndex) {
    const imageData = await component.handler();
    if (!component.dontMoveToNewPage) {
      this.doc.addPage('a4', 'landscape');
      this.currentY = 30;
    } else {
      this.currentY += 5;
    }
    this.addImage({
      src: imageData.data,
      width: imageData.width,
      height: imageData.height,
      proportionOfWidthOfPage: 0.98,
      alignment: component.alignment ? component.alignment : 'center',
      addTitle: component.title,
      specificYPosition: component.specificYPosition
        ? component.specificYPosition
        : undefined,
      specificXPosition: component.specificXPosition
        ? component.specificXPosition
        : undefined,
    });
    // Adjust the y position after adding the chart.
    // For now don't do anything if specific position was indicets.
    if (
      !component.specificXPosition &&
      !component.specificYPosition &&
      !component.dontIncrementYPosition
    ) {
      if (component.specificHeight) {
        this.currentY += component.specificHeight + 5;
      } else {
        this.currentY += imageData.height + 5;
      }
    } else {
      // if dont increment y is chosen, walk back y
      this.currentY -= 5;
    }
  }

  restoreMainTextColor() {
    this.doc.setTextColor(mainColors.mainBlue);
  }

  addPageTitlesAndImages() {
    // add client logo
    this.addClientLogo(true, {
      adjustedHeightProportion:
        this.configName === 'gem_funds' ? 0.02 : undefined,
    });
    // add risksystem logo
    this.addClientLogo(true, {
      imageSrc: RiskSystemLogo,
      adjustedYPosition: PdfGenerator.WIDTH - 7,
      adjustedAlignment: 'right',
      adjustedHeightProportion: 0.022,
    });
    this.addPageTitle();
    this.addPositionDate();
    this.addCopyrightText();
    this.restoreMainTextColor();
  }

  async addTable(tableComponent: ComponentIndex) {
    /*
    TODO:
      - When you pass through a table, just specify the number of tables alongside it
      - Next steps
        -

    */
    const data = await tableComponent.handler();

    /*
    we map over the list of fields in the data
    if the corresponding value is neither a string nor a number, it means that we don't want to include it
    in the table

    This process of mapping and filtering will give us an array of keys that we want to filter out
    */

    if (!tableComponent.dontMoveToNewPage) {
      this.doc.addPage('a4', 'landscape');
      this.currentY = 25;
    }

    if (data.body.length < 1) {
      const rowWithNoData = data.columns.map((col: any) => ({
        [col.dataKey]: 'No data',
      }));
      data.body[0] = rowWithNoData[0];
    }

    let fieldsToRemove: (string | null)[] = [];
    if (!data.dontCropHeaderRow) {
      fieldsToRemove = Object.keys(data.body[0])
        .map((keyName) => {
          if (
            typeof data.body[0][keyName] !== 'string' &&
            typeof data.body[0][keyName] !== 'number'
          ) {
            return keyName;
          } else {
            return null;
          }
        })
        .filter((key) => key !== null);
    }

    let y = tableComponent.specificYPosition || this.currentY;
    const x = tableComponent.specificXPosition || null;

    if (tableComponent.additionalComponentDetail) {
      this.doc.setTextColor('#ED8B00');
      this.doc.setFontSize(16);
      this.doc.text(tableComponent.additionalComponentDetail, 5, y);
      y = y + 5;
      this.restoreMainTextColor();
    }
    // here we do HEIGHT because it's landscape
    // Everything has a right margin except the last table
    const availableSpace = PdfGenerator.HEIGHT - 10; // this is the width on the x axis
    // Hard coding three columns
    const numberOfColumns = tableComponent.tablesInRow || 1; // we might not need this if there aren't multiple tables
    const numberOfGaps = numberOfColumns - 1; // or n - 1
    // const widthOfEachColumn = (availableSpace - (5 * numberOfGaps)) / numberOfColumns
    // if we want to use a 12 grid

    // take all of the space, and remove the amount needed for gaps of 5, and then divide by 12
    const oneTwelfth = (availableSpace - 5 * numberOfGaps) / 12;

    const tableWidth = tableComponent.specifiedWidth
      ? oneTwelfth * tableComponent.specifiedWidth
      : 'auto';

    // add table title
    this.doc.setFontSize(
      tableComponent.specifiedWidth && tableComponent.specifiedWidth < 4
        ? 10
        : 12,
    );
    this.doc.text(
      tableComponent.title,
      x ? x : this.widthsPopulated + 5,
      y + 1,
      {
        maxWidth: tableWidth,
      },
    );
    // this is where we remove the columns that don't have what we want
    const renderColumns = data.columns.filter(
      (col: any) =>
        !fieldsToRemove.includes(col.dataKey) && col.dataKey in data.body[0],
    );

    const columnStyles = renderColumns.reduce((prev: any, current: any) => {
      const { width } = current;
      prev[current.dataKey] = {
        halign: current.headerStyle?.textAlign || 'left',
        cellWidth: current.pdfWidth
          ? ((tableWidth === 'auto' ? PdfGenerator.HEIGHT : tableWidth) / 100) *
            current.pdfWidth
          : 'auto',
      };
      return prev;
    }, {});

    this.doc.autoTable({
      startY: y + 3.2,
      tableWidth,
      margin: {
        left: x
          ? x
          : !tableComponent.specifiedWidth || this.widthsPopulated === 0
            ? 5
            : this.widthsPopulated + 5,
        right: 5,
        top: 22,
        bottom: 10,
      },
      body: data.body,
      columns: renderColumns,
      didDrawPage: (data: any) => {
        this.currentPage++;
        data.settings.margin.top = 18;
      },
      didParseCell: (data: any) => {
        if (data.section === 'head') {
          if (data.column.raw.headerStyle) {
            if (typeof data.column.raw.headerStyle === 'function') {
              data.cell.styles.halign = data.column.raw.headerStyle().textAlign;
            } else {
              data.cell.styles.halign = data.column.raw.headerStyle.textAlign;
            }
          }
        }
        if (data.section === 'body') {
          // handle styling
          if (data.column.raw.cellStyle) {
            if (typeof data.column.raw.cellStyle === 'function') {
              const rowData = data.row.raw;
              const allData = data.table.body.map((el: any) => el.raw);
              data.cell.styles.halign = data.column.raw.cellStyle(
                allData,
                rowData,
              ).textAlign;
            } else {
              data.cell.styles.halign = data.column.raw.cellStyle.textAlign;
            }
          }
          try {
            if (data.column.raw.render) {
              // THIS IS WHERE WE HANDLE RENDERING
              const renderType = data.column.raw.pdfRenderType;
              data.cell.raw = data.column.raw.render(data.row.raw); // is this line pointless?
              const convertedValue = data.column.raw.render(data.row.raw);

              // we see what the output of the rendering process is
              // if it's just a string, we apply that to the text

              // otherwise we're going to have to do things with it
              if (typeof convertedValue === 'string') {
                data.cell.text[0] = convertedValue;
              } else if (typeof convertedValue === 'number') {
                data.cell.text[0] = convertedValue.toString();
              } else if (data.column.raw.pdfRenderType) {
                try {
                  if (renderType === 'CustomBackgroundColor') {
                    data.cell.styles.fillColor = data.cell.raw.props.cellColor;
                    data.cell.styles.textColor = '#fff';
                    if (data.cell.raw.props.value) {
                      data.cell.text[0] = data.cell.raw.props.value;
                    }
                  } else if (renderType === 'PageLinkButton') {
                    data.cell.styles.fillColor = mapStatusToColor(
                      specifyStatus(data.cell.raw.props.status),
                    );
                    data.cell.styles.textColor = '#fff';
                    if (data.cell.raw.props.text) {
                      data.cell.text[0] = data.cell.raw.props.text;
                    }
                  } else if (renderType === 'HeatMapCell') {
                    data.cell.styles.fillColor = data.cell.raw.props.color;
                    data.cell.styles.textColor = '#fff';
                    if (data.cell.raw.props.value) {
                      data.cell.text[0] = data.cell.raw.props.value;
                    }
                  } else if (renderType === 'DetailLine') {
                    data.cell.text[0] = data.cell.raw.props.initialText;
                  } else if (renderType === 'FillBox') {
                    data.cell.text[0] = percentageToTwoDecimalPlacesNoHundred(
                      data.cell.raw.props.value,
                    );
                  } else if (renderType === 'Table') {
                    data.cell.text[0] = '';
                  } else if (renderType === 'StatusWithPercentage') {
                    data.cell.styles.fillColor = mapStatusToColor(
                      specifyStatus(data.cell.raw.props.status),
                    );
                    data.cell.styles.textColor = '#fff';
                    if (data.cell.raw.props.innerText) {
                      data.cell.text[0] = data.cell.raw.props.innerText;
                    }
                  } else {
                    if (convertedValue.toString() === '[object Object]') {
                      // we don't know how to render the cell
                      data.cell.text[0] = '-';
                    } else {
                      data.cell.text[0] = convertedValue.toString();
                    }
                  }
                  // This cell has a special render type (see CustomColumn type definition) that we need to use
                } catch (err) {
                  console.error(err);
                }
              }
            }
          } catch (err) {
            console.error(err);
          }
        }
      },
      willDrawCell: (data: any) => {
        if (data.row.raw.noteType === 'table') {
          const stringForFormatting = data.row.raw.noteValue;
          const tableString = stringForFormatting.slice(
            stringForFormatting.indexOf('{'),
          );
          try {
            const tableData = JSON.parse(tableString);
            // here we need to create as much space as the table needs
            data.row.height = tableData.data.length * 5 + 15;
            // data.cell.height = 7 * tableData.data.length + 20;
          } catch (err) {
            console.error(err);
          }
        }
      },
      didDrawCell: (data: any) => {
        if (
          data.column.raw.pdfRenderType &&
          data.column.raw.pdfRenderType === 'DetailLine' &&
          data.cell.raw.props?.detailLine
        ) {
          this.doc.setFontSize(4);
          this.doc.text(
            data.cell.raw.props.detailLine,
            data.cell.x + data.cell.contentWidth - 20,
            data.cell.y + 6,
            data,
          );
          this.doc.setFontSize(10);
        }
        if (
          data.column.raw.pdfRenderType &&
          data.column.raw.pdfRenderType === 'Table' &&
          data.row.raw.noteValue.includes('TABLE_NOTE')
        ) {
          const stringForFormatting = data.row.raw.noteValue;
          const tableString = stringForFormatting.slice(
            stringForFormatting.indexOf('{'),
          );
          try {
            const tableData = JSON.parse(tableString);
            this.doc.autoTable({
              startY: data.cell.y + 2,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 2,
              styles: {
                fontSize: 6,
              },
              columnStyles,
              columns: tableData.columns.map((col: any) => {
                return {
                  dataKey: col.field,
                  header: col.title,
                };
              }),
              body: tableData.data,
            });
          } catch (err) {
            console.error(err);
          }
        }

        // remove the actual data
      },
      columnStyles,
      styles: {
        fontSize: 6,
        valign: 'top',
      },
      // columnStyles: renderColumns.reduce((prev: any, current: any ) => {
      //   prev[]
      // }, {})
      headStyles: {
        fillColor: mainColors.mainBlue,
      },
    });
    if (tableComponent.tablesInRow) {
      if (!this.tallestTableInRow) {
        this.tallestTableInRow = this.doc.lastAutoTable.finalY;
      } else {
        this.tallestTableInRow = Math.max(
          this.tallestTableInRow,
          this.doc.lastAutoTable.finalY,
        );
      }
    }
    if (tableComponent.specifiedWidth) {
      // create next column
      this.widthsPopulated += oneTwelfth * tableComponent.specifiedWidth + 5;
    }
    if (
      this.widthsPopulated >= PdfGenerator.HEIGHT - oneTwelfth ||
      this.widthsPopulated === 0
    ) {
      y = this.tallestTableInRow
        ? this.tallestTableInRow + 6
        : this.doc.lastAutoTable.finalY + 6;
      this.tallestTableInRow = null;
      this.widthsPopulated = 0;
    }
    this.currentY = y;
  }

  save() {
    const pageCount = this.doc.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      this.doc.setPage(i);
      this.addPageTitlesAndImages();
    }
    this.doc.save(this.pdfId);
  }

  async generatePdf(components: (ComponentIndex | undefined)[]) {
    for (const comp of components) {
      if (!this.positionDate && comp?.positionDate) {
        this.positionDate = comp?.positionDate;
      }
    }
    // Check for table groups in the components.
    const orderedComponents: (ComponentIndex | undefined)[] = [];
    const groupIndicesEncountered: number[] = [];
    for (let i = 0; i < components.length; i++) {
      // If the index is already in the group indices array, skip it.
      if (groupIndicesEncountered.includes(i)) {
        continue;
      }
      if (components[i] !== undefined && components[i]?.groupName) {
        // Get the group name we are dealin with
        const currentGroupName = components[i]?.groupName;
        // Add the index to the group indices array
        groupIndicesEncountered.push(i);
        // Add the component to its own array
        const currentGroup: (ComponentIndex | undefined)[] = [components[i]];
        // Now search for the rest of the components in this group.
        // Store their indicies so they can be removed safely.
        const currentGroupIndicies: number[] = [];
        // Loop through the remainder of the array and check for any more components in this group.
        for (let j = i + 1; j < components.length; j++) {
          // Store the index if the component belongs to the current group.
          if (components[j]?.groupName === currentGroupName) {
            currentGroupIndicies.push(j);
            groupIndicesEncountered.push(j);
            currentGroup.push(components[j]);
          }
        }
        // Dort the components in this group by groupOrder
        currentGroup.sort((a, b) => {
          if (a?.groupOrder && b?.groupOrder) {
            return a.groupOrder - b.groupOrder;
          }
          return 0;
        });
        // Now add the components to the orderedComponents array.
        for (const component of currentGroup) {
          orderedComponents.push(component);
        }
      } else {
        orderedComponents.push(components[i]);
      }
    }
    // reset the component
    for (const comp of orderedComponents) {
      if (comp) {
        if (comp.type === PdfComponentType.TABLE) {
          await this.addTable(comp);
        } else if (
          comp.type === PdfComponentType.LINE_CHART ||
          comp.type === PdfComponentType.BAR_CHART ||
          comp.type === PdfComponentType.AREA_CHART
        ) {
          await this.addChart(comp);
        } else if (comp.type === PdfComponentType.GENERAL_COMPONENT_IMAGE) {
          await this.addGeneralComponentImage(comp);
        }
      }
    }
    this.save();
  }

  setPage(num: number) {
    this.doc.setPage(num);
  }
}

export const GeneralPdfExportButton = () => {
  // This component will decide if a custom pdf export button should be used for
  // this is decided by checking for an element on the current page that is tagged with the
  // id pdf-target
  const pdfTarget = document.getElementById('pdf-target');

  return pdfTarget ? (
    <CustomPdfExportButton rootElementId="pdf-target" />
  ) : (
    <PdfExportButton />
  );
};

const PdfExportButton = () => {
  const componentsToExport = useSelector(componentsForExportSelector);
  const selectedDate = useTitleBarDatePicker('marketDate'); // This handles the instance of the markets-overview-page
  const currentPage = useSelector(activePageSelector);
  const currentSection = useSelector(activeSectionSelector);
  const activeFund = useSelector(
    createActiveFundSelectorBySection(currentSection),
  );
  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const configName = useSelector(configNameSelector) || 'mersenne_funds';
  const pageDetails = useSelector(
    createPageDetailsSelector(currentPage, currentSection),
  );
  const activeDate = useSelector(activeDateSelector);

  const pdfExportHandler = async () => {
    new PdfGenerator({
      pageTitle: pageDetails?.pageTitle || 'Title',
      fundName: activeFund?.name || '',
      pageId: currentPage || 'pageId',
      fundId: activeFund?.id || selectedDate || '',
      clientName,
      configName,
      analysisDate:
        activeDate || formatDateForCheckingState(new Date()) || 'today',
    }).generatePdf(componentsToExport);
  };

  return (
    <div>
      <Tooltip title="Export page to PDF">
        <IconButton onClick={pdfExportHandler}>
          <Archive />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default PdfExportButton;
