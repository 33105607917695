import React, { useEffect, useRef } from 'react';
import { createChart, IChartApi, ColorType } from 'lightweight-charts';
import { mainColors } from '../../../../styling/theme';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../../types/redux/pdfExports/pdfExportsStore';
import html2canvas from 'html2canvas';

interface ChartProps {
  graphData: {
    price_time: string[];
    price: number[];
    price_on: true | false;
  };
}

const Chart: React.FC<ChartProps> = ({ graphData }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const dispatch = useDispatch();

  const handleDownload = async () => {
    if (!chartContainerRef.current) return;

    try {
      const data = await html2canvas(chartContainerRef.current).then((canvas) =>
        canvas.toDataURL('image/png', 1.0),
      );
      return {
        data,
        width: chartContainerRef.current.getBoundingClientRect().width,
        height: chartContainerRef.current.getBoundingClientRect().height,
      };
    } catch (error) {
      console.error('Failed to capture the chart as a PNG:', error);
    }
  };

  // TODO: Will add this later to the PDF
  // useEffect(() => {
  //   dispatch(
  //     addComponentToPdfExport({
  //       identifier: 'markets_overview_graph',
  //       handler: handleDownload,
  //       type: PdfComponentType.LINE_CHART,
  //       title: 'Markets Overview Graph',
  //       groupOrder: -1,
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    if (!chartContainerRef.current || graphData.price.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor: 'black',
        background: { color: 'white' as ColorType },
      },
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
      handleScroll: {
        horzTouchDrag: false,
        vertTouchDrag: false,
        mouseWheel: false,
      },
    });

    const transformedData = graphData.price_time.map((time, index) => ({
      time: time,
      value: graphData.price[index],
    }));

    const firstPrice = graphData.price[0];
    const lastPrice = graphData.price[graphData.price.length - 1];

    let lineColour: string;

    if (graphData.price_on) {
      // Use the existing logic for when `price_on` is true
      lineColour =
        firstPrice > lastPrice
          ? mainColors.Fail_darker
          : mainColors.Pass_darker;
    } else {
      // Determine line color based on `lastPrice`
      lineColour =
        lastPrice >= 0 ? mainColors.Pass_darker : mainColors.Fail_darker;
    }

    const areaSeries = chart.addAreaSeries({
      lastValueVisible: false,
      crosshairMarkerVisible: false,
      lineColor: 'transparent',
      topColor:
        lineColour === mainColors.Fail_darker
          ? 'rgba(255, 0, 0, 0.8)'
          : 'rgba(77, 228, 39, 0.8)',
      bottomColor:
        lineColour === mainColors.Fail_darker
          ? 'rgba(255, 216, 216, 0.2)'
          : 'rgba(221, 250, 213, 0.2)',
    });

    areaSeries.setData(transformedData);
    const lineSeries = chart.addLineSeries({
      color: lineColour,
      lineWidth: 4,
    });
    lineSeries.setData(transformedData);
    lineSeries.setMarkers([
      {
        time: graphData.price_time[graphData.price_time.length - 1],
        position: 'inBar',
        color: lineColour,
        shape: 'square',
      },
    ]);
    chart.timeScale().fitContent();

    chartRef.current = chart;
    return () => {
      chart.remove();
    };
  }, [graphData]);

  // Handle chart resizing
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current && chartContainerRef.current) {
        chartRef.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
        });
        chartRef.current.timeScale().fitContent();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }} />
  );
};

export default Chart;
